<template>
  <div>
    <NuxtErrorBoundary>
      <ModuleHeader
      :navigation-items="result"
      :key="$route.path"
      class="fixed z-20 h-17 top-0 w-full"
      />
      <NuxtLoadingIndicator color="#1ADF69" />
        <NuxtPage/>
        <!-- <NuxtPage :key="$route.fullPath" /> -->
        <ModuleFooter />
        <template #error="{ error }">
          <ModuleError
            @closeError="(link) => recoverFromError(error, link)"
            :error="error"
          />
        </template>
    </NuxtErrorBoundary>
  </div>
</template>
<script lang="ts" setup>
// init sever, get all pages and get/set locale
import type { GetNavigationQuery } from "@/types/graphql";
import getNavigation from "@/graphql/navigation.query.graphql";

// get locale for language switch
const { locale } = useI18n();

// get pages
const { result, error, onResult, refetch } = await useQuery<GetNavigationQuery>(
  getNavigation,
  {
    locale: locale.value,
  }
);

const recoverFromError = async (error: any, link: string) => {
  await navigateTo(link);
  error.value = null;
};

watch(locale, async () => {
  await refetch({ locale: locale.value });
});
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
