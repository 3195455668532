import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBaseButton, LazyBaseCard, LazyBaseFilter, LazyBaseImage, LazyBaseQuote, LazyBaseTag, LazyBaseText, LazyBaseTwoColumnList, LazyBaseVideo, LazyBlockAccordion, LazyBlockArticleSlider, LazyBlockArticleTeaser, LazyBlockBlogList, LazyBlockBlogTeaser, LazyBlockCtaDownload, LazyBlockFooter, LazyBlockHeaderBlog, LazyBlockHeaderCampaign, LazyBlockHeaderCase, LazyBlockHeaderDefault, LazyBlockHeaderMember, LazyBlockHeaderReferenceDetail, LazyBlockHeaderReference, LazyBlockHeaderRenderer, LazyBlockHeaderStage, LazyBlockHeader, LazyBlockIconGrid, LazyBlockImageGrid, LazyBlockImageListCta, LazyBlockLogoImageGrid, LazyBlockMemberList, LazyBlockPageSlider, LazyBlockPartner02, LazyBlockPartner, LazyBlockPriceCard, LazyBlockQuote, LazyBlockReferenceList, LazyBlockReferenceSlider2, LazyBlockReferenceSlider, LazyBlockReferenceTeaserCta, LazyBlockReferenceTeaser, LazyBlockRenderer, LazyBlockTabModule, LazyBlockTeaserHint, LazyBlockTextCta, LazyBlockTextImage, LazyBlockText, LazyLayoutBlock, LazyModuleAccordionItem, LazyModuleBlockHeadline, LazyModuleError, LazyModuleFooter, LazyModuleHeader, LazyModuleLinkCard, LazyModuleMenuExpanded, LazyModuleSliderControls, LazyModuleSlider, LazyTemplateBlogArticle, LazyTemplatePage, LazyTemplateReference, LazyTemplateRenderer, LazyTemplateTeam } from '#components'
const lazyGlobalComponents = [
  ["BaseButton", LazyBaseButton],
["BaseCard", LazyBaseCard],
["BaseFilter", LazyBaseFilter],
["BaseImage", LazyBaseImage],
["BaseQuote", LazyBaseQuote],
["BaseTag", LazyBaseTag],
["BaseText", LazyBaseText],
["BaseTwoColumnList", LazyBaseTwoColumnList],
["BaseVideo", LazyBaseVideo],
["BlockAccordion", LazyBlockAccordion],
["BlockArticleSlider", LazyBlockArticleSlider],
["BlockArticleTeaser", LazyBlockArticleTeaser],
["BlockBlogList", LazyBlockBlogList],
["BlockBlogTeaser", LazyBlockBlogTeaser],
["BlockCtaDownload", LazyBlockCtaDownload],
["BlockFooter", LazyBlockFooter],
["BlockHeaderBlog", LazyBlockHeaderBlog],
["BlockHeaderCampaign", LazyBlockHeaderCampaign],
["BlockHeaderCase", LazyBlockHeaderCase],
["BlockHeaderDefault", LazyBlockHeaderDefault],
["BlockHeaderMember", LazyBlockHeaderMember],
["BlockHeaderReferenceDetail", LazyBlockHeaderReferenceDetail],
["BlockHeaderReference", LazyBlockHeaderReference],
["BlockHeaderRenderer", LazyBlockHeaderRenderer],
["BlockHeaderStage", LazyBlockHeaderStage],
["BlockHeader", LazyBlockHeader],
["BlockIconGrid", LazyBlockIconGrid],
["BlockImageGrid", LazyBlockImageGrid],
["BlockImageListCta", LazyBlockImageListCta],
["BlockLogoImageGrid", LazyBlockLogoImageGrid],
["BlockMemberList", LazyBlockMemberList],
["BlockPageSlider", LazyBlockPageSlider],
["BlockPartner02", LazyBlockPartner02],
["BlockPartner", LazyBlockPartner],
["BlockPriceCard", LazyBlockPriceCard],
["BlockQuote", LazyBlockQuote],
["BlockReferenceList", LazyBlockReferenceList],
["BlockReferenceSlider2", LazyBlockReferenceSlider2],
["BlockReferenceSlider", LazyBlockReferenceSlider],
["BlockReferenceTeaserCta", LazyBlockReferenceTeaserCta],
["BlockReferenceTeaser", LazyBlockReferenceTeaser],
["BlockRenderer", LazyBlockRenderer],
["BlockTabModule", LazyBlockTabModule],
["BlockTeaserHint", LazyBlockTeaserHint],
["BlockTextCta", LazyBlockTextCta],
["BlockTextImage", LazyBlockTextImage],
["BlockText", LazyBlockText],
["LayoutBlock", LazyLayoutBlock],
["ModuleAccordionItem", LazyModuleAccordionItem],
["ModuleBlockHeadline", LazyModuleBlockHeadline],
["ModuleError", LazyModuleError],
["ModuleFooter", LazyModuleFooter],
["ModuleHeader", LazyModuleHeader],
["ModuleLinkCard", LazyModuleLinkCard],
["ModuleMenuExpanded", LazyModuleMenuExpanded],
["ModuleSliderControls", LazyModuleSliderControls],
["ModuleSlider", LazyModuleSlider],
["TemplateBlogArticle", LazyTemplateBlogArticle],
["TemplatePage", LazyTemplatePage],
["TemplateReference", LazyTemplateReference],
["TemplateRenderer", LazyTemplateRenderer],
["TemplateTeam", LazyTemplateTeam],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
