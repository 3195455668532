<script lang="ts" setup>
import Arrow from "@/assets/icons/arrow.svg?component";
const props = withDefaults(
  defineProps<{
    title: string;
    link?: string;
    level?: 1 | 2 | 3 | 4 | 5 | 6;
  }>(),
  {
    level: 2,
  }
);
</script>
<template>
  <component :is="`h${level}`" class="text-s font-pixel module-block-headline mb-8">
    <nuxt-link
      v-if="props.link"
      :to="props.link"
      class="flex items-baseline gap-2 leading-1"
      >{{ props.title }} <Arrow class="w-4" />
    </nuxt-link>
    <template v-else>{{ props.title }}</template>
  </component>
</template>
