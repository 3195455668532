<script setup lang="ts">
import Search from "@/assets/icons/search.svg?component";
import Arrow from "@/assets/icons/arrow.svg?component";
import type { GetNavigationQuery } from "@/types/graphql";
import getNavigation from "@/graphql/navigation.query.graphql";

const { t, locale } = useI18n({
  messages: {
    de: {
      "404": "Seite nicht gefunden",
      home: "Zur Startseite",
      panic: "Keine Panik!",
      infoText:
        "Der Link, den du nutzt, ist zwar nicht mehr verfügbar, aber keine Sorge - wir haben nur etwas umstrukturiert. Suchst du einen alten Blockartikel? Der findet sich bestimmt {blog} wieder. Wenn du nicht fündig wirst, zögere nicht {contact}.",
      agency: "Agentur",
      agencyLink: "/agentur",
      services: "Leistungen",
      servicesLink: "/leistungen",
      blog: "Blog",
      blogLink: "/blog",
      reference: "Referenzen",
      referenceLink: "/referenzen",
      contactLink: "/kontakt",
      here: "hier",
      contactUs: "uns zu fragen",
    },
    en: {
      "404": "Page not found",
      home: "Go Home",
      panic: "Don't panic!",
      infoText:
        "The link you're using is no longer available, but don't worry - we just restructured it a bit. Looking for an old block article? You're sure to find it {blog}. If you can't find it, don't hesitate to {contact}.",
      agency: "Agency",
      agencyLink: "/agency",
      services: "Services",
      servicesLink: "/services",
      blog: "Blog",
      blogLink: "/blog_english",
      reference: "References",
      referenceLink: "/references",
      contactLink: "/contact",
      here: "here",
      contactUs: "contact us",
    },
  },
});
const props = defineProps({
  error: Object,
});
const emit = defineEmits<{
  (e: "closeError", link: string): void;
}>();

useHeaderColor("black");
// get pages
const { data, error } = await useAsyncQuery<GetNavigationQuery>(getNavigation, {
  locale: locale.value,
});

const recoverFromError = async (error: any, link: string) => {
  emit("closeError", link);
};

function pad(value: string | number) {
  return value.toString().padStart(2, "0");
}

const items = ref([
  {
    slug: t("servicesLink"),
    name: t("services"),
  },
  {
    slug: t("blogLink"),
    name: t("blog"),
  },
  {
    slug: t("referenceLink"),
    name: t("reference"),
  },
  {
    slug: t("agencyLink"),
    name: t("agency"),
  },
]);
console.error("Error", props.error);
console.error({
  code: props.error?.statusCode,
  message: props.error?.message,
  error: JSON.stringify(props.error),
});
</script>

<template>
  <ModuleHeader
    color="white"
    :navigation-items="data"
    class="fixed z-20 h-17 top-0 w-full"
    @changeRoute="(link) => recoverFromError(props.error, link)"
  />
  <layout-block
    :title="'Home'"
    :link="'/'"
    class="text-cta bg-integer-black text-integer-white min-h-screen !pt-40"
    @changeRoute="(link) => recoverFromError(props.error, link)"
  >
    <div class="sm:grid grid-cols-12">
      <div class="relative col-start-1 col-end-8 max-w-prose self-start">
        <div>
          <div class="flex items-center font-pixel">
            <Search />
            <h2 class="ml-2 text-h2">{{ props.error?.statusCode ?? "404" }}</h2>
          </div>
          <h1 class="text-h2 md:text-h1 2xl:text-h1max text-integer-light-green mt-2 font-pixel hyphens-auto">
            {{ t("panic") }}
          </h1>

          <p v-if="locale === 'de'" class="text-default">
            Der Link, den du nutzt, ist zwar nicht mehr verfügbar, aber keine
            Sorge - wir haben nur etwas umstrukturiert. Suchst du einen alten
            Blogartikel? Der findet sich bestimmt
            <NuxtLink
              @click="recoverFromError(props.error, '/blog')"
              :to="'/blog'"
              class="text-integer-light-green"
            >
              hier
            </NuxtLink>
            wieder. Wenn du nicht fündig wirst, zögere nicht
            <NuxtLink
              @click="recoverFromError(props.error, '/kontakt')"
              :to="'/kontakt'"
              class="text-integer-light-green"
            >
              uns zu fragen </NuxtLink
            >.
          </p>

          <p v-else-if="locale === 'en'" class="text-default">
            The link you're using is no longer available, but don't worry - we
            just restructured it a bit. Looking for an old blog article? You're
            sure to find it
            <NuxtLink
              @click="recoverFromError(props.error, '/blog_english')"
              :to="'/blog_english'"
              class="text-integer-light-green"
            >
              here </NuxtLink
            >. If you can't find it, don't hesitate to
            <NuxtLink
              @click="recoverFromError(props.error, '/contact')"
              :to="'/contact'"
              class="text-integer-light-green"
            >
              contact us</NuxtLink
            >.
          </p>
        </div>
      </div>
      <div class="links col-start-8 col-end-13 grid mt-4 sm:mt-0 pl-4">
        <nuxt-link
          v-for="(item, index) in items"
          :to="item.slug"
          @click="recoverFromError(props.error, item.slug)"
          class="link flex items-center leading-1 group transition border-b border-gray py-8 gap-5.2 text-gray hover:text-integer-white"
        >
          <span class="self-start">
            {{ pad(index + 1) }}
          </span>
          <span
            class="text-h2 break-all group-hover:translate-x-2 transition"
            >{{ item?.name }}</span
          >
          <base-button
            type="primary"
            transparent
            class="ml-auto right-2 transition"
            ><template #icon-right><Arrow /></template
          ></base-button>
        </nuxt-link>
      </div>
    </div>
  </layout-block>
</template>
